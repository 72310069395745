import React from "react"
import { css } from "@emotion/react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Navbar from "../components/Navbar"

const Submit = () => {
  return (
    <Layout>
      <SEO title="Submit" />

      <div
        css={css`
          text-align: center;
          margin-bottom: 50px;
          margin-top: 30px;
        `}
      >
        <h1
          css={css`
            margin-bottom: 0;
          `}
        >
          Submit Blog
        </h1>
        <p>(This may take a few seconds to load...)</p>
      </div>

      <div
        css={css`
          max-width: 800px;
          margin: 0 auto;
        `}
      >
        <iframe
          class="airtable-embed"
          src="https://airtable.com/embed/shr33nvDVUo9JFdmY?backgroundColor=red"
          frameborder="0"
          onmousewheel=""
          height="650"
          width="100%"
          style={{ background: "transparent", border: "1px solid #ccc" }}
        ></iframe>
      </div>
    </Layout>
  )
}

export default Submit
